import React from 'react'

const Phone = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M12.0173 11.1171L10.5504 12.9509C8.27829 11.6156 6.38479 9.72232 5.0493 7.45041L6.88313 5.98347C7.32502 5.6296 7.47287 5.02235 7.24312 4.50496L5.57129 0.74097C5.32383 0.183266 4.70897 -0.113277 4.11845 0.0402851L0.936787 0.86568C0.322196 1.02625 -0.0751407 1.62113 0.0119427 2.25034C1.15926 10.4199 7.58037 16.8408 15.7501 17.9881C16.3792 18.075 16.9739 17.6777 17.1344 17.0633L17.9598 13.8813C18.1131 13.2909 17.8168 12.6764 17.2596 12.4285L13.4959 10.7571C12.9785 10.5272 12.3711 10.6751 12.0173 11.1171Z" fill="#7352DF"/>
  </svg>
  )
}

export default Phone