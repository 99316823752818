import React from 'react';
import {useTranslation} from 'react-i18next';
// Components
import {Text, Title} from '@Components/typography';
import {TitleTag, TitleVariant} from '@Components/typography/Title';
// import NewsletterForm from '@Src/components/NewsletterForm';

const Newsletter = () => {
	const {t} = useTranslation();

	return (
		<div className='footer__newsletter'>
			<Title
				tag={TitleTag.h5}
				variant={TitleVariant.standard}
				classes='footer__newsletter-title'>
				{t('footer.newsletterTitle')}
			</Title>
			{/* <Text>Nie przegap najświeższych aktualizacji i ekskluzywnych ofert</Text> */}
			<Text>{t('footer.newsletterText')}</Text>
			{/* <NewsletterForm /> */}
		</div>
	);
};

export default Newsletter;
