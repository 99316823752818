import React from 'react';

const PageCode = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='54'
			height='49'
			viewBox='0 0 54 49'
			fill='none'>
			<circle
				cx='14.0687'
				cy='14.0687'
				r='14.0687'
				fill='#FFEE95'
				fillOpacity='0.7'
			/>
			<path
				d='M49.2483 14H15.6414C14.1826 14 13 15.182 13 16.64V44.36C13 45.818 14.1826 47 15.6414 47H49.2483C50.7071 47 51.8897 45.818 51.8897 44.36V16.64C51.8897 15.182 50.7071 14 49.2483 14Z'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M14.7472 20.4323H49.5602'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinejoin='round'
			/>
			<path
				d='M40.0947 28.1592L45.6545 33.7159L40.0947 39.2727'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M24.7951 39.2727L19.2354 33.7159L24.7951 28.1592'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M35.5221 26.77L29.368 40.6619'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M18 18C18.5523 18 19 17.5523 19 17C19 16.4477 18.5523 16 18 16C17.4477 16 17 16.4477 17 17C17 17.5523 17.4477 18 18 18Z'
				fill='#252B42'
			/>
			<path
				d='M22 18C22.5523 18 23 17.5523 23 17C23 16.4477 22.5523 16 22 16C21.4477 16 21 16.4477 21 17C21 17.5523 21.4477 18 22 18Z'
				fill='#252B42'
			/>
		</svg>
	);
};

export default PageCode;
