import React from 'react';

const ThinkHead = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='51'
			height='59'
			viewBox='0 0 51 59'
			fill='none'>
			<path
				d='M50.1375 44.0689C50.1375 51.8388 43.8387 58.1375 36.0688 58.1375C28.2989 58.1375 22.0001 51.8388 22.0001 44.0689C22.0001 36.2989 28.2989 30.0002 36.0688 30.0002C43.8387 30.0002 50.1375 36.2989 50.1375 44.0689Z'
				fill='#C6ECE6'
			/>
			<path
				d='M8.19283 45.2938L8.19256 36.5488C8.19256 35.0501 7.58805 33.6382 6.59638 32.5145C3.34597 28.8321 1.54061 23.8477 2.10121 18.4317C2.97927 9.94955 9.82474 3.04573 18.3011 2.11458C29.3861 0.897003 38.7655 9.54441 38.7655 20.3827C38.7655 20.7686 38.7519 21.1513 38.7271 21.5312C38.6909 22.0813 38.783 22.6311 39.0272 23.1253L43.2289 31.6292C43.6464 32.474 43.1602 33.4863 42.24 33.6886L39.6115 34.2664C38.9791 34.4057 38.5081 34.936 38.4449 35.5806L37.8393 41.7534C37.6422 43.7623 35.9531 45.2938 33.9343 45.2938H26.0865C22.9364 45.2938 20.3828 42.7402 20.3828 39.5903V38.7656'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M24.6422 18.9421C24.6422 21.173 22.8336 22.9815 20.6027 22.9815C18.3718 22.9815 16.5632 21.173 16.5632 18.9421C16.5632 16.7111 18.3718 14.9026 20.6027 14.9026M31.7964 16.7829L28.9216 16.1716C28.6941 16.1269 28.4282 15.9182 28.3302 15.7083L27.2672 13.8683C27.1342 13.6784 27.0867 13.3439 27.1618 13.1247L28.0715 10.3309C28.1458 10.1139 28.0439 9.84237 27.8453 9.72766L24.9611 8.06246C24.7623 7.94793 24.4764 7.99538 24.3255 8.16816L22.3618 10.3532C22.2097 10.5277 21.8961 10.6534 21.6652 10.6331L19.5403 10.6322C19.3091 10.6525 18.9953 10.5264 18.8429 10.3519L16.8734 8.16965C16.7223 7.99687 16.4361 7.94983 16.2374 8.06437L13.3646 9.72427C13.166 9.83902 13.0638 10.1105 13.1376 10.3278L14.0433 13.1232C14.118 13.3427 14.0699 13.6773 13.9367 13.8668L12.8746 15.7077C12.7769 15.9176 12.511 16.1265 12.2836 16.1712L9.40999 16.7823C9.18501 16.8267 9.00082 17.0508 9.00064 17.2803L9 20.6035C9 20.833 9.18392 21.0571 9.40908 21.1016L12.2832 21.7133C12.5104 21.7583 12.7765 21.9668 12.8744 22.1769L13.9385 24.0161C14.0717 24.2057 14.1193 24.5402 14.0443 24.7593L13.1333 27.5534C13.0589 27.7706 13.1608 28.0419 13.3595 28.1566L16.2443 29.8215C16.4429 29.9362 16.7289 29.8888 16.8799 29.7158L18.8429 27.5316C18.9955 27.3569 19.3089 27.2311 19.5398 27.2518L21.6646 27.2527C21.8955 27.2327 22.2092 27.3588 22.362 27.5331L24.3311 29.7155C24.4823 29.8879 24.7685 29.9353 24.9671 29.8204L27.8398 28.1607C28.0384 28.0459 28.1405 27.7741 28.0667 27.557L27.161 24.7619C27.0863 24.5424 27.1344 24.2077 27.2675 24.0183L28.3301 22.1775C28.4277 21.9673 28.6937 21.7589 28.9208 21.7138L31.7949 21.103C32.0198 21.0586 32.2041 20.8344 32.2043 20.605L32.2054 17.2807C32.2056 17.0512 32.0215 16.8271 31.7964 16.7829Z'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default ThinkHead;
