import React from 'react';

const Cross = () => {
	return (
		<svg
			className='cross'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 32 32'
			fill='none'>
			<line
				x1='21.1836'
				y1='23.9773'
				x2='11.6836'
				y2='7.52285'
				stroke='#E1E1E1'
				strokeWidth='3'
				strokeLinecap='round'
			/>
			<line
				x1='7.52389'
				y1='20.3172'
				x2='23.9784'
				y2='10.8172'
				stroke='#E1E1E1'
				strokeWidth='3'
				strokeLinecap='round'
			/>
		</svg>
	);
};

export default Cross;
