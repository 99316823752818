import React from 'react';

const People = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='60'
			height='55'
			viewBox='0 0 60 55'
			fill='none'>
			<circle
				cx='14.0687'
				cy='14.0687'
				r='14.0687'
				fill='#FFEE95'
				fillOpacity='0.7'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M25.2729 41.2451H20.5846C20.2122 41.2451 19.9099 41.5273 19.9099 41.874V49.8941H13.5975V41.874C13.5975 41.5273 13.2946 41.2451 12.9229 41.2451H9V31.0628C9 26.9693 12.4061 23.7632 16.7537 23.7632C20.2701 23.7632 22.3616 24.7486 23.7481 27.0571C24.7597 28.7409 25.2729 31.3011 25.2729 34.6664V41.2451Z'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M57.832 41.2451H53.1436C52.7713 41.2451 52.469 41.5273 52.469 41.874V49.8941H46.156V41.874C46.156 41.5273 45.8537 41.2451 45.4813 41.2451H41.5591V31.0628C41.5591 26.9693 44.9652 23.7632 49.3128 23.7632C52.8292 23.7632 54.9207 24.7486 56.3072 27.0571C57.3188 28.7409 57.832 31.3011 57.832 34.6664V41.2451Z'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M41.5458 45.102H36.8575C36.4852 45.102 36.1829 45.3841 36.1829 45.7309V53.7509H29.8705V45.7309C29.8705 45.3841 29.5676 45.102 29.1958 45.102H25.2729V34.9197C25.2729 30.8262 28.679 27.62 33.0267 27.62C36.543 27.62 38.6346 28.6054 40.021 30.9139C41.0327 32.5978 41.5458 35.158 41.5458 38.5232V45.102Z'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M21.8597 15.7907C21.8597 18.4362 19.7145 20.5814 17.069 20.5814C14.4229 20.5814 12.2783 18.4362 12.2783 15.7907C12.2783 13.1452 14.4229 11 17.069 11C19.7145 11 21.8597 13.1452 21.8597 15.7907Z'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M38.243 19.0692C38.243 21.7147 36.0978 23.8592 33.4523 23.8592C30.8068 23.8592 28.6616 21.7147 28.6616 19.0692C28.6616 16.423 30.8068 14.2785 33.4523 14.2785C36.0978 14.2785 38.243 16.423 38.243 19.0692Z'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M54.4318 15.7907C54.4318 18.4362 52.2873 20.5814 49.6418 20.5814C46.9956 20.5814 44.8511 18.4362 44.8511 15.7907C44.8511 13.1452 46.9956 11 49.6418 11C52.2873 11 54.4318 13.1452 54.4318 15.7907Z'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default People;
