import React from 'react';

const LogoTopPart = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 211 140'
			fill='none'
			className='logo-top-part'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M140.58 140C179.533 140 211 108.657 211 70.1184C210.976 31.3426 179.509 0 140.58 0C127.508 0 114.983 3.7166 104.573 10.0845L0 70.142L104.026 129.915C114.698 136.283 127.223 140 140.58 140Z'
				fill='#9172F6'
			/>
		</svg>
	);
};

export default LogoTopPart;
