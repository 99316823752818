import React from 'react';

const Error = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='73'
			height='73'
			viewBox='0 0 73 73'
			fill='none'>
			<g clipPath='url(#clip0_417_12914)'>
				<path
					d='M36.5 73C16.3737 73 0 56.6263 0 36.5C0 16.3737 16.3737 0 36.5 0C42.9037 0 49.2036 1.68224 54.7182 4.86518C55.4654 5.29665 55.7226 6.25555 55.2912 7.00275C55.012 7.48387 54.4894 7.78358 53.9288 7.78358C53.6571 7.78358 53.3891 7.71154 53.1536 7.57567C48.1152 4.66703 42.3565 3.12924 36.5 3.12924C18.0992 3.12924 3.12924 18.0993 3.12924 36.5C3.12924 54.9007 18.0992 69.8708 36.5 69.8708C54.9008 69.8708 69.8707 54.9008 69.8707 36.5C69.8707 35.0074 69.7711 33.507 69.5751 32.0405C69.2306 29.4599 68.583 26.923 67.6499 24.5007C67.0263 22.8894 66.2775 21.3237 65.4239 19.8463C65.2149 19.4843 65.1593 19.0625 65.2676 18.6591C65.3758 18.2556 65.6348 17.9178 65.9972 17.7084C66.2346 17.5707 66.5026 17.4986 66.7743 17.4986C67.3334 17.4986 67.8552 17.7991 68.1355 18.2829C69.072 19.905 69.8905 21.6185 70.5676 23.3742C71.5917 26.0313 72.3013 28.8079 72.6768 31.6266C72.8914 33.2289 73 34.8682 73 36.5C73 56.6263 56.6262 73 36.5 73Z'
					fill='#FA4138'
				/>
				<path
					d='M48.1447 49.7097C47.727 49.7097 47.334 49.547 47.0384 49.2514L37.04 39.2531C36.8907 39.1037 36.6952 39.0291 36.4996 39.0291C36.304 39.0291 36.1084 39.1037 35.9591 39.2531L25.9607 49.2514C25.6651 49.547 25.2721 49.7097 24.8545 49.7097C24.4368 49.7097 24.0437 49.547 23.7481 49.2514C23.1386 48.6415 23.1386 47.6487 23.7481 47.0388L33.7465 37.0405C34.0452 36.7419 34.0452 36.2581 33.7465 35.9595L23.7481 25.9612C23.1386 25.3513 23.1386 24.3585 23.7481 23.7486C24.0437 23.453 24.4368 23.2903 24.8545 23.2903C25.2721 23.2903 25.6651 23.453 25.9607 23.7486L35.9591 33.7469C36.1084 33.8963 36.304 33.9709 36.4996 33.9709C36.6952 33.9709 36.8907 33.8963 37.04 33.7469L47.0384 23.7486C47.334 23.453 47.727 23.2903 48.1447 23.2903C48.5623 23.2903 48.9554 23.453 49.251 23.7486C49.8605 24.3585 49.8605 25.3513 49.251 25.9612L39.2526 35.9595C38.954 36.2581 38.954 36.7419 39.2526 37.0405L49.251 47.0388C49.8605 47.6487 49.8605 48.6415 49.251 49.2514C48.9554 49.547 48.5624 49.7097 48.1447 49.7097Z'
					fill='#FA4138'
				/>
			</g>
			<defs>
				<clipPath id='clip0_417_12914'>
					<rect width='73' height='73' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Error;
