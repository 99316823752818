import React from 'react';

const MovieCover = () => {
	return (
		<svg
			viewBox='0 0 730 555'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M730 0H0V555H730V0ZM310.365 38.5L564.019 184.628C599.517 204.892 619.007 242.714 618.201 281.047C619.007 319.271 599.517 356.946 564.019 377.503L310.329 523.631C258.931 553.186 192.331 535.519 162.328 483.798C132.727 432.48 150.422 365.981 202.222 336.024L297.837 280.938L202.222 225.705C150.422 196.15 132.727 129.213 162.364 77.8943C191.965 26.6122 258.968 8.54277 310.365 38.5Z'
				fill='white'
			/>
		</svg>
	);
};

export default MovieCover;
