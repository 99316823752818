import React from 'react';

const BorderCircle = () => {
	return (
		<svg
			className='border-circle'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 38 38'
			fill='none'>
			<circle cx='19' cy='19' r='17' stroke='#DFDAFF' strokeWidth='4' />
		</svg>
	);
};

export default BorderCircle;
