import React from 'react';

const Grow = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='49'
			height='62'
			viewBox='0 0 49 62'
			fill='none'>
			<circle cx='34.0911' cy='15.0545' r='14.0687' fill='#C8E7CB' />
			<path
				d='M2 43.8435V36.1745'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M11.3948 43.8434V26.743'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M20.754 35.4617C20.7438 32.5583 20.7552 29.6548 20.7893 26.7516'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M30.184 17.285C30.1493 21.8788 30.1445 26.4728 30.1729 31.0666'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M2.15112 35.8256L16.2924 21.6745L20.9616 26.3437L34.5002 12.9999'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M29.9819 12.0005H35.3847V17.4034'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M40.7793 45.2541C40.7793 50.7573 36.4262 55.2184 31.0566 55.2184C25.687 55.2184 21.334 50.7573 21.334 45.2541C21.334 39.7509 25.6869 35.29 31.0566 35.29C36.4262 35.29 40.7793 39.7511 40.7793 45.2541Z'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M25.6772 46.2538C25.6772 42.6432 28.5332 39.7163 32.0562 39.7163'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M36.3525 53.7213L41.8623 59.368C42.6843 60.2112 44.0177 60.2112 44.8384 59.368C45.662 58.5269 45.662 57.1596 44.8384 56.3189L39.3285 50.6709'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default Grow;
