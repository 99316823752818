import React from 'react';
import {Text} from '@Components/typography';
// Icons
import {Location, Phone, Mail} from '@Src/assets/icons/_index';

const Data = () => {
	return (
		<div className='footer__data'>
			<Text classes='footer__data-item'>
				<a
					className='footer__data-link'
					href='https://www.google.pl/maps/place/WebsiteAB/@52.3800094,15.7009093,19z/data=!3m1!4b1!4m5!3m4!1s0x47068b4e30570333:0xc72a8c0b8d43c9f5!8m2!3d52.3800086!4d15.7014565?hl=pl'
					target='_blank'
					rel='noopener noreferrer'>
					<span className='footer__data-icon'>
						<Location />
					</span>
					<span className='footer__data-text'>
						Innovate Tech Solutions
						<br />
						Bukowiec 3, 66-300 Międzyrzecz
					</span>
				</a>
				<span className='footer__data-link'>
					<span className='footer__data-icon'></span>
					<span className='footer__data-text'>
						NIP: 5961763380 <span className='no-brake-word'>REGON: 389994460</span>
					</span>
				</span>
			</Text>
			<Text classes='footer__data-item'>
				<a className='footer__data-link' href='tel:+48572713510'>
					<span className='footer__data-icon'>
						<Phone />
					</span>
					<span className='footer__data-text'>+48 572713510</span>
				</a>
				<a className='footer__data-link' href='mailto:contact@innovate-ts.com'>
					<span className='footer__data-icon'>
						<Mail />
					</span>
					<span className='footer__data-text'>contact@innovate-ts.com</span>
				</a>
			</Text>
		</div>
	);
};

export default Data;
