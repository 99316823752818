import React from 'react';

const PageCard = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='64'
			height='61'
			viewBox='0 0 64 61'
			fill='none'>
			<circle cx='42.3848' cy='39.3843' r='21.5' fill='#DFDAFF' />
			<path
				d='M46.0833 2H3.91667C2.85812 2 2 2.85066 2 3.9V38.1C2 39.1493 2.85812 40 3.91667 40H46.0833C47.1419 40 48 39.1493 48 38.1V3.9C48 2.85066 47.1419 2 46.0833 2Z'
				stroke='#252B42'
				strokeWidth='3'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M10.325 12.6377C11.6021 12.6377 12.6375 11.6024 12.6375 10.3252C12.6375 9.04804 11.6021 8.0127 10.325 8.0127C9.04779 8.0127 8.01245 9.04804 8.01245 10.3252C8.01245 11.6024 9.04779 12.6377 10.325 12.6377Z'
				fill='#252B42'
			/>
			<path
				d='M16.8 12.6377C18.0772 12.6377 19.1125 11.6024 19.1125 10.3252C19.1125 9.04804 18.0772 8.0127 16.8 8.0127C15.5229 8.0127 14.4875 9.04804 14.4875 10.3252C14.4875 11.6024 15.5229 12.6377 16.8 12.6377Z'
				fill='#252B42'
			/>
		</svg>
	);
};

export default PageCard;
