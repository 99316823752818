import React from 'react';

const UserWithStar = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='70'
			height='60'
			viewBox='0 0 70 60'
			fill='none'>
			<circle cx='48.5' cy='38.5' r='21.5' fill='#FAD7C9' />
			<path
				d='M25.5 35C34.6127 35 42 27.6127 42 18.5C42 9.3873 34.6127 2 25.5 2C16.3873 2 9 9.3873 9 18.5C9 27.6127 16.3873 35 25.5 35Z'
				stroke='#252B42'
				strokeWidth='3'
				strokeMiterlimit='10'
			/>
			<path
				d='M2 46C4.87931 42.5554 8.47529 39.7855 12.5352 37.8852C16.5951 35.9848 21.0204 35 25.5 35C29.9796 35 34.4049 35.9848 38.4648 37.8852C42.5247 39.7855 46.1207 42.5554 49 46'
				stroke='#252B42'
				strokeWidth='3'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M56.5 32C58.9853 32 61 30.2091 61 28C61 25.7909 58.9853 24 56.5 24C54.0147 24 52 25.7909 52 28C52 30.2091 54.0147 32 56.5 32Z'
				stroke='#252B42'
				strokeWidth='3'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M56 24V20'
				stroke='#252B42'
				strokeWidth='3'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M53 26L50 24'
				stroke='#252B42'
				strokeWidth='3'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M53 30L50 32'
				stroke='#252B42'
				strokeWidth='3'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M56 32V36'
				stroke='#252B42'
				strokeWidth='3'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M60 30L63 32'
				stroke='#252B42'
				strokeWidth='3'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M60 26L63 24'
				stroke='#252B42'
				strokeWidth='3'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default UserWithStar;
