// Libraries
import React from 'react';
import {Link} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';
import {useTranslation} from 'react-i18next';
// Components
import Container from '@Components/Container';
import Data from './components/Data';
import SocialMedia from './components/SocialMedia';
import Services from './components/Services';
import Newsletter from './components/Newsletter';
// Types
import {Tag} from '@Types/tags';

const Footer = () => {
	const {t} = useTranslation();

	return (
		<Container as={Tag.Footer} classes='footer'>
			<div className='footer__logo'>
				<StaticImage
					src='../../../../assets/images/logo.svg'
					alt='Innovate tech solutions'
					loading='lazy'
					placeholder='blurred'
					formats={['auto', 'webp']}
					objectFit='contain'
				/>
			</div>
			<Data />
			<SocialMedia />
			<Services />
			<Newsletter />

			<p className='footer__bottom'>
				<small>
					&copy; Innovate Tech Solutions{' '}
					{t('footer.allRights')}{' '}
					<Link to={t('routes.policy.link')}>{t('routes.policy.text')}</Link>
				</small>
			</p>
		</Container>
	);
};

export default Footer;
