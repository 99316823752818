// Libraries
import React, {useState} from 'react';
import {Link} from 'gatsby';
import {spawn} from 'child_process';

interface LinkProps {
	text: string;
	link: string;
	isHiglited?: boolean;
	isCurrentLang?: boolean;
}

export interface NavbarItemProps extends LinkProps {
	children?: LinkProps[];
	toggleExpandHandler: () => void;
}

const NavbarItem = (props: NavbarItemProps) => {
	const {text, link, children, isHiglited, toggleExpandHandler, isCurrentLang} =
		props;
	const [isExpanded, setIsExpanded] = useState(false);

	const expandHandler = () => setIsExpanded(!isExpanded);

	return (
		<li
			className={'navbar__menu-item'}
			data-is-higlited={isHiglited}
			data-is-current-lang={isCurrentLang}>
			{!!link ? (
				<span className='menu-item__inline'>
					<Link
						onClick={() => {
							if (!!children?.length) {
								expandHandler();
							} else {
								toggleExpandHandler();
							}
						}}
						to={link}>
						{text}
					</Link>
					{!!children?.length && (
						<button
							onClick={(e) => {
								e.preventDefault();
								if (!!children?.length) {
									expandHandler();
								} else {
									toggleExpandHandler();
								}
							}}></button>
					)}
				</span>
			) : (
				<span
					onClick={() => {
						if (!!children?.length) {
							expandHandler();
						} else {
							toggleExpandHandler();
						}
					}}>
					{text}
				</span>
			)}
			{!!children?.length && (
				<ul className='navbar__submenu' data-is-expanded={isExpanded}>
					{children.map(({text, link}, index) => (
						<li key={text.slice(3) + index} className='navbar__submenu-item'>
							<Link onClick={toggleExpandHandler} to={link}>
								{text}
							</Link>
						</li>
					))}
				</ul>
			)}
		</li>
	);
};

export default NavbarItem;
