import React from 'react';

const Location = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='20'
			viewBox='0 0 16 20'
			fill='none'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8 20C8 20 16 12.3219 16 7.61905C16 2.7981 11.866 0 8 0C4.134 0 0 2.7981 0 7.61905C0 12.3219 8 20 8 20ZM8 10.5882C9.69934 10.5882 11.0769 9.27141 11.0769 7.64705C11.0769 6.02268 9.69934 4.70587 8 4.70587C6.30066 4.70587 4.92308 6.02268 4.92308 7.64705C4.92308 9.27141 6.30066 10.5882 8 10.5882Z'
				fill='#7352DF'
			/>
		</svg>
	);
};

export default Location;
