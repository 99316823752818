import React from 'react';

const LaptopService = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='54'
			height='45'
			viewBox='0 0 54 45'
			fill='none'>
			<circle cx='39.0911' cy='15.0545' r='14.0687' fill='#C8E7CB' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10.841 14.7984C10.841 16.1908 11.969 17.3189 13.3614 17.3189C14.7538 17.3189 15.8828 16.1908 15.8828 14.7984C15.8828 13.406 14.7538 12.278 13.3614 12.278C11.969 12.278 10.841 13.406 10.841 14.7984Z'
				stroke='#252B42'
				strokeWidth='2.4'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M4.65421 16.4117V13.0962H6.46348C6.64681 12.3541 6.94402 11.6603 7.33868 11.0293L6.04566 9.73625L8.39046 7.39144L9.69989 8.70087C10.3271 8.32068 11.0151 8.03313 11.7504 7.85848V6H15.0659V7.85848C15.7993 8.03313 16.4882 8.32068 17.1155 8.70087L18.4249 7.39144L20.7697 9.73625L19.4767 11.0293C19.8713 11.6603 20.1685 12.3541 20.3519 13.0962H22.1611V16.4117H20.3856C20.2139 17.1711 19.9186 17.8813 19.5268 18.5278L20.7697 19.7707L18.4249 22.1155L17.1984 20.889C16.549 21.2953 15.8321 21.5992 15.0659 21.7806V23.5079H11.7504V21.7806C10.9833 21.5992 10.2663 21.2953 9.6169 20.889L8.39046 22.1155L6.04566 19.7707L7.2885 18.5278C6.89577 17.8813 6.60243 17.1711 6.4297 16.4117H4.65421Z'
				stroke='#252B42'
				strokeWidth='2.4'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M6.72024 26.2291C6.70054 33.3142 6.71209 26.2293 6.71209 26.3422C6.71209 32.2918 6.71141 31.2659 6.71413 37.2156C6.71413 37.507 6.7481 37.7977 6.76916 38.1391C6.65435 38.1391 6.53546 38.1391 6.41589 38.1391C5.261 38.1391 4.10611 38.1378 2.95121 38.1398C2.29292 38.1412 1.99265 38.4434 2.00012 39.0917C2.01779 40.7414 3.00217 42.2633 4.38668 42.7359C4.69103 42.84 5.02798 42.8955 5.34932 42.8955C19.3779 42.9029 33.4064 42.9063 47.435 42.8955C48.954 42.8941 49.8684 41.9658 50.4214 40.6718C50.6368 40.1681 50.7108 39.5873 50.7564 39.0343C50.8046 38.4515 50.452 38.1425 49.8583 38.1405C48.6918 38.1364 47.526 38.1391 46.3596 38.1391C46.2373 38.1391 46.115 38.1391 46.039 38.1391C46.039 37.7646 46.039 37.4515 46.039 37.1385C46.039 29.3073 46.039 21.4754 46.039 13.6441C46.039 12.1283 45.1096 11.1994 43.5919 11.1994C37.9418 11.1987 32.2923 11.1987 26.6422 11.2007C26.473 11.2007 26.2977 11.1967 26.1367 11.2386'
				stroke='#252B42'
				strokeWidth='2.4'
				strokeMiterlimit='10'
				strokeLinecap='round'
			/>
			<path
				d='M46.0386 38.1396H6.75044'
				stroke='#252B42'
				strokeWidth='2.4'
				strokeMiterlimit='10'
				strokeLinecap='round'
			/>
		</svg>
	);
};

export default LaptopService;
