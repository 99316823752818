import React from 'react';

const Search = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='47'
			height='58'
			viewBox='0 0 47 58'
			fill='none'>
			<circle cx='32.4944' cy='14.7928' r='14.0687' fill='#F9DCEF' />
			<path
				d='M7.28545 55.2797C6.73422 55.7294 6.02444 56 5.24936 56C3.49774 56 2.0791 54.6189 2.0791 52.916C2.0791 52.0934 2.41021 51.3457 2.94931 50.7936'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M28.1117 15C19.4854 15 12.4917 21.993 12.4917 30.6195C12.4917 39.246 19.4854 46.2381 28.1117 46.2381C36.737 46.2381 43.7298 39.246 43.7298 30.6195C43.7298 21.993 36.737 15 28.1117 15'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M7.28516 55.3492L18.9994 43.6349'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M3.38037 50.7939L15.0947 39.0796'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M28.6601 20.8571C34.0076 21.1601 38.0975 25.7425 37.7945 31.089'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default Search;
