import React from 'react';

const Triangle = () => {
	return (
		<svg
			className='triangle'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 26 28'
			fill='none'>
			<path
				d='M21.715 0.564211C23.0283 -0.150708 24.633 0.775789 24.6706 2.27062L25.249 25.3123C25.2881 26.8738 23.6023 27.8755 22.2496 27.0945L1.42733 15.0728C0.0746055 14.2918 0.0992155 12.3309 1.47111 11.5841L21.715 0.564211Z'
				fill='#F4F4F4'
			/>
		</svg>
	);
};

export default Triangle;
