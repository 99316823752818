import React from 'react';

const Plan = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='54'
			height='57'
			viewBox='0 0 54 57'
			fill='none'>
			<circle cx='14.0687' cy='14.0687' r='14.0687' fill='#FFDEDE' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M47.4888 51.0373H20.473C19.8283 51.0373 19.3003 50.5092 19.3003 49.864V16.0926C19.3003 15.4474 19.8283 14.9199 20.473 14.9199H47.4888C48.1346 14.9199 48.6621 15.4474 48.6621 16.0926V49.864C48.6621 50.5092 48.1346 51.0373 47.4888 51.0373Z'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M26.137 11.1805H16.4634C15.6587 11.1805 15 11.8392 15 12.6439V53.5359C15 54.3412 15.6587 54.9999 16.4634 54.9999H51.2199C52.0252 54.9999 52.6833 54.3412 52.6833 53.5359V12.6439C52.6833 11.8392 52.0252 11.1805 51.2199 11.1805H41.7175'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M37.2534 24.2742H44.7624'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M31.9976 34.6892H44.7628'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M31.9976 45.3328H44.7628'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M31.9976 24.274H32.7485'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M24.7627 22.8676L25.845 24.2705L28.5172 21.2669'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M24.7627 33.3805L25.845 34.7827L28.5172 31.7791'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M24.7627 44.6436L25.845 46.0461L28.5172 43.0426'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M41.6506 14.9201V11.0731C41.6506 10.6301 41.2902 10.2691 40.8471 10.2691H38.2679C37.7674 10.2691 37.3296 9.93384 37.1977 9.45151C36.8062 8.00803 35.487 7 33.9908 7H33.7962C32.2988 7 30.9796 8.00803 30.5881 9.45151C30.4568 9.93384 30.0184 10.2691 29.5185 10.2691H26.9386C26.4962 10.2691 26.1357 10.6301 26.1357 11.0731V14.9201'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default Plan;
