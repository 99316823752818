import React from 'react';

const TwoHeads = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='55'
			height='53'
			viewBox='0 0 55 53'
			fill='none'>
			<circle cx='40.5135' cy='38.9027' r='14.0687' fill='#D1EAF2' />
			<path
				d='M34.5123 47V38.45H38.8952C40.6274 38.45 42.0767 37.1606 42.2458 35.4691L42.7656 30.272C42.8198 29.7295 43.2238 29.2829 43.7665 29.1658L46.0219 28.6792C46.8116 28.5089 47.2285 27.6566 46.8705 26.9454L43.2653 19.7856C43.0556 19.3696 42.9766 18.9066 43.0077 18.4436C43.029 18.1239 43.0406 17.8016 43.0406 17.4766C43.0406 8.92207 35.9671 1.98846 27.246 2.00001H24.0821V11.0379H20.7416C18.888 11.0379 17.1021 12.5094 17.005 14.3256C16.898 16.3293 18.5221 17.9868 20.5409 17.9868H24.0821L24.0823 26.6407H27.4229C29.2766 26.6407 31.0625 28.1122 31.1594 29.9285C31.2663 31.9322 29.6422 33.5898 27.6233 33.5898H24.0821V47'
				stroke='white'
				strokeWidth='2.5'
				strokeLinejoin='round'
			/>
			<path
				d='M34.4676 47V38.45H38.8662C40.6045 38.45 42.059 37.1606 42.2288 35.4691L42.7504 30.272C42.8048 29.7295 43.2103 29.2829 43.7549 29.1658L46.0184 28.6792C46.8109 28.5089 47.2294 27.6566 46.87 26.9454L43.2519 19.7856C43.0415 19.3696 42.9622 18.9066 42.9933 18.4436C43.0147 18.1239 43.0264 17.8016 43.0264 17.4766C43.0264 8.92207 35.9276 1.98846 27.1752 2.00001H24'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinejoin='round'
			/>
			<path
				d='M14.004 47V38.45H9.79086C8.12581 38.45 6.7327 37.1606 6.57005 35.4691L6.07047 30.272C6.01837 29.7295 5.62997 29.2829 5.10828 29.1658L2.94025 28.6792C2.18119 28.5089 1.78024 27.6566 2.12458 26.9454L5.59012 19.7856C5.79162 19.3696 5.86761 18.9066 5.83777 18.4436C5.8173 18.1239 5.80608 17.8016 5.80608 17.4766C5.80608 8.92207 12.6054 1.98846 20.9889 2.00001H24'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinejoin='round'
			/>
			<path
				d='M23.9999 4V10.9635H20.6982C18.8661 10.9635 17.1009 12.438 17.0049 14.2579C16.8992 16.2658 18.5045 17.9268 20.4998 17.9268H23.9999L24.0001 26.5985H27.302C29.1341 26.5985 30.8993 28.0731 30.9951 29.8931C31.1007 31.901 29.4954 33.562 27.5 33.562H23.9999V47'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default TwoHeads;
