import React from 'react';
import {Link} from 'gatsby';
import {useTranslation} from 'react-i18next';
// Components
import {Text, Title} from '@Components/typography';
import {TitleTag, TitleVariant} from '@Components/typography/Title';

const Services = () => {
	const {t} = useTranslation();

	const links = [
		{
			id: 'l01',
			text: t('routes.allServices.websites.text'),
			link: t('routes.allServices.websites.link'),
		},
		{
			id: 'l02',
			text: t('routes.allServices.apps.text'),
			link: t('routes.allServices.apps.link'),
		},
	];

	return (
		<div className='footer__services'>
			<Title
				tag={TitleTag.h5}
				variant={TitleVariant.standard}
				classes='footer__services-title'>
				{t('footer.services')}
			</Title>
			<ul className='footer__services-list'>
				{links.map(({link, text, id}) => (
					<li key={id} className='footer__services-item'>
						<Link to={link}>
							<Text as='span'>{text}</Text>
						</Link>
					</li>
				))}
			</ul>
		</div>
	);
};

export default Services;
