import React from 'react';

const QAndA = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='64'
			height='56'
			viewBox='0 0 64 56'
			fill='none'>
			<circle cx='49.514' cy='41.9027' r='14.0687' fill='#D1EAF2' />
			<path
				d='M34.2595 16.2227H53.2602C54.7669 16.2227 55.9995 17.4651 55.9995 18.9835V38.104C55.9995 39.6224 54.7669 40.8649 53.2602 40.8649H48.6698V48.151C48.6698 49.1038 47.5267 49.581 46.8582 48.9073L39.8199 40.8649H26.961C25.4543 40.8649 24.2217 39.6224 24.2217 38.104V26.1258'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M31.2602 1.55615H4.96096C3.45428 1.55615 2.22168 2.79843 2.22168 4.317V23.4375C2.22168 24.9559 3.45428 26.1984 4.96096 26.1984H9.55136V33.4844C9.55136 34.4373 10.6945 34.9145 11.363 34.2408L18.4012 26.1984H31.2602C32.7669 26.1984 33.9995 24.9559 33.9995 23.4375V4.317C33.9995 2.79843 32.7669 1.55615 31.2602 1.55615Z'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M18.111 17.4448V16.9413C18.111 15.3446 18.9961 14.435 19.9582 13.4727C20.9053 12.5254 21.7777 11.6296 21.7777 10.0802C21.7777 8.07565 20.1328 6.44482 18.111 6.44482C16.0892 6.44482 14.4443 8.07565 14.4443 10.0802'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M18.25 22.5C18.9404 22.5 19.5 21.9404 19.5 21.25C19.5 20.5596 18.9404 20 18.25 20C17.5596 20 17 20.5596 17 21.25C17 21.9404 17.5596 22.5 18.25 22.5Z'
				fill='#252B42'
			/>
			<path
				d='M40.1113 32.1113V21.1113'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M40.25 37.5C40.9404 37.5 41.5 36.9404 41.5 36.25C41.5 35.5596 40.9404 35 40.25 35C39.5596 35 39 35.5596 39 36.25C39 36.9404 39.5596 37.5 40.25 37.5Z'
				fill='#252B42'
			/>
			<path
				d='M36.25 37.5C36.9404 37.5 37.5 36.9404 37.5 36.25C37.5 35.5596 36.9404 35 36.25 35C35.5596 35 35 35.5596 35 36.25C35 36.9404 35.5596 37.5 36.25 37.5Z'
				fill='#252B42'
			/>
			<path
				d='M32.25 37.5C32.9404 37.5 33.5 36.9404 33.5 36.25C33.5 35.5596 32.9404 35 32.25 35C31.5596 35 31 35.5596 31 36.25C31 36.9404 31.5596 37.5 32.25 37.5Z'
				fill='#252B42'
			/>
		</svg>
	);
};

export default QAndA;
