import React from 'react';

const Like = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='76'
			height='76'
			viewBox='0 0 76 76'
			fill='none'>
			<circle cx='44.5' cy='49.5' r='21.5' fill='#D1EAF2' />
			<path
				d='M5.28571 29H19V56H5.28571C4.67951 56 4.09812 55.7812 3.66947 55.3917C3.24082 55.0022 3 54.4739 3 53.9231V31.0769C3 30.5261 3.24082 29.9978 3.66947 29.6083C4.09812 29.2188 4.67951 29 5.28571 29V29Z'
				stroke='#252B42'
				strokeWidth='3'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M19 27.1739L29.9451 5C32.2673 5 34.4944 5.93447 36.1365 7.59784C37.7786 9.2612 38.7011 11.5172 38.7011 13.8696V20.5217H55.6386C56.2594 20.5202 56.8733 20.6532 57.4391 20.9119C58.0049 21.1706 58.5095 21.5489 58.919 22.0215C59.3286 22.4941 59.6335 23.05 59.8134 23.6518C59.9932 24.2537 60.0439 24.8876 59.9619 25.5109L56.6784 52.1196C56.5455 53.1877 56.0336 54.1705 55.2381 54.8845C54.4426 55.5985 53.4179 55.995 52.3551 56H19'
				stroke='#252B42'
				strokeWidth='3'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default Like;
