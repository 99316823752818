import React from 'react';

const DesktopCode = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='60'
			height='52'
			viewBox='0 0 60 52'
			fill='none'>
			<circle cx='45.0687' cy='37.0689' r='14.0687' fill='#C6ECE6' />
			<path
				d='M32.3477 22.4519L37.7562 18.1566L32.3477 13.9527'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M19.985 13.9527L15.3491 18.1566L19.985 22.4519'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M23.8486 23.9972L30.0298 11.6347'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M49.0024 2H4.02407C2.89985 2 2 2.89985 2 4.02441V35.059C2 36.1835 2.89985 37.1584 4.09909 37.1584L32.3604 37.2331L49.0774 37.083C50.2017 37.083 51.1762 36.1835 51.1762 34.9843V4.02441C51.1012 2.89985 50.1266 2 49.0024 2Z'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M2.2251 31.9854H50.9515'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M21.1158 37.2333L17.3677 42.4807'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M31.6104 37.2333L35.3588 42.4807'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M14.3555 42.6143H38.3571'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default DesktopCode;
