import React from 'react';

const Utils = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='50'
			height='54'
			viewBox='0 0 50 54'
			fill='none'>
			<circle cx='14.2288' cy='14.6653' r='14.0687' fill='#FFDEDE' />
			<path
				d='M37.7692 39.4614L33.1538 44.0768'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M41.0769 45.0767L38 48.1536'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M28.7692 26.2852L14.9044 12L8 19.069L21.9028 33.5385'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M26.4614 38.0463L40.8349 51.9997L47.9999 45.1461L33.4739 31.2305'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M43.6794 12L11.2013 44.4776L11.2017 44.478L8 52L15.5219 48.7985L48 16.3208L43.6794 12Z'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M40.3081 15.0767L46.1711 20.9597C46.5589 21.349 46.5589 21.98 46.1711 22.3692L40.406 28.1536'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M12.6152 45.8462L15.6922 48.9231'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M20.6154 22L16 26.6154'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M16.0769 19L13 22.0769'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default Utils;
