import React, {useState, useEffect} from 'react';
import {Link} from 'gatsby';
import {useTranslation} from 'react-i18next';
// Components
import Button, {ButtonVariant} from '@Src/components/Button';

const Cookies = () => {
	const [visible, setVisible] = useState(false);
	const {t} = useTranslation();

	useEffect(() => {
		setTimeout(() => setVisible(true), 5000);
	}, []);

	const acceptCookiesHandler = () => {
		setVisible(false);
		localStorage?.setItem('cookiesPolicyAccepted', 'true');
	};

	return (
		<div className='cookies' data-is-visible={visible}>
			<button
				className='cookies__close'
				onClick={acceptCookiesHandler}
				aria-label={t('commmon.close') as string}>
				<span></span>
				<span></span>
			</button>
			<p className='cookies__title'>{t('cookies.title')}</p>
			<p className='cookies__text'>
				{t('cookies.description')}
				<Link to={t('routes.policy.link')}> {t('routes.policy.text')}</Link>.
			</p>
			<Button variant={ButtonVariant.Dark} onClick={acceptCookiesHandler}>
				{t('common.accept')}
			</Button>
		</div>
	);
};

export default Cookies;
