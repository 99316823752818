import React from 'react';
// Icons
import {Facebook} from '@Src/assets/icons/_index';

const SocialMedia = () => {
	return (
		<div className='footer__social-media'>
			<a
				href='https://www.facebook.com/profile.php?id=61550971856687'
				target='_blank'
				rel='noopener noreferrer'
				aria-label='Facebook'>
				<Facebook />
			</a>
		</div>
	);
};

export default SocialMedia;
