import React from 'react';

const Facebook = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='33'
			height='31'
			viewBox='0 0 33 31'
			fill='none'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M33 15.4549C33 6.92019 25.6118 0 16.5 0C7.38817 0 0 6.92019 0 15.4549C0 23.1685 6.03281 29.5623 13.9219 30.7228V19.9237H9.73132V15.4549H13.9219V12.05C13.9219 8.17728 16.3858 6.03637 20.1543 6.03637C21.9597 6.03637 23.8484 6.33857 23.8484 6.33857V10.1423H21.7667C19.7182 10.1423 19.0774 11.3331 19.0774 12.5571V15.4549H23.6532L22.9225 19.9237H19.0781V30.7241C26.9672 29.5643 33 23.1706 33 15.4549Z'
				fill='white'
			/>
		</svg>
	);
};

export default Facebook;
