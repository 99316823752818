import React from 'react';

const Star = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 37 36' fill='none'>
			<path
				d='M36.8926 13.3599C36.6295 12.5563 35.9358 11.9727 35.1033 11.8532L25.0041 10.3847L20.4879 1.23411C20.1147 0.478336 19.3444 0 18.4977 0C17.6557 0 16.8854 0.478336 16.5075 1.23411L11.9913 10.3847L1.89676 11.8532C1.05954 11.9727 0.365843 12.5611 0.107501 13.3599C-0.150842 14.1635 0.0644437 15.0437 0.667243 15.632L7.97259 22.7544L6.25031 32.809C6.10679 33.6413 6.45124 34.4832 7.13059 34.9759C7.81472 35.4734 8.71892 35.5355 9.46524 35.1433L18.4977 30.3934L27.5301 35.1433C27.8554 35.3155 28.2094 35.3968 28.5634 35.3968C29.0227 35.3968 29.482 35.2533 29.8647 34.9759C30.5489 34.4784 30.8885 33.6413 30.745 32.809L29.0227 22.7544L36.3281 15.632C36.9357 15.0437 37.1509 14.1635 36.8926 13.3599Z'
				fill='#7352DF'
			/>
		</svg>
	);
};

export default Star;
