import React from 'react';

const Mail = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='17'
			height='14'
			viewBox='0 0 17 14'
			fill='none'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M2.12506 0.000127369H14.8747C15.4401 -0.00613512 15.9842 0.218768 16.3841 0.624043C16.7839 1.02932 17.0058 1.58078 16.9996 2.1539V11.8459C17.0058 12.419 16.7839 12.9704 16.3841 13.3757C15.9842 13.781 15.4401 14.0059 14.8747 13.9996H2.12506C1.55962 14.0059 1.01554 13.781 0.61569 13.3757C0.215839 12.9704 -0.006053 12.419 0.000125664 11.8459V2.1539C-0.006053 1.58078 0.215839 1.02932 0.61569 0.624043C1.01554 0.218768 1.55962 -0.00613512 2.12506 0.000127369ZM8.81862 8.50752L15.1934 3.66154C15.3453 3.54611 15.4248 3.35736 15.4021 3.16638C15.3794 2.97539 15.2579 2.81119 15.0834 2.73562C14.9088 2.66006 14.7078 2.68461 14.556 2.80003L8.49988 7.40371L2.4438 2.80003C2.20909 2.6216 1.87611 2.66982 1.70008 2.90772C1.52404 3.14561 1.57161 3.48311 1.80632 3.66154L8.18114 8.50752C8.37002 8.6511 8.62974 8.6511 8.81862 8.50752Z'
				fill='#7352DF'
			/>
		</svg>
	);
};

export default Mail;
