import React from 'react';

const Checked = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='76'
			height='76'
			viewBox='0 0 76 76'
			fill='none'>
			<circle cx='44.5' cy='49.5' r='21.5' fill='#FFEE95' />
			<path
				d='M10.5048 58.4952C7.93942 55.9298 9.64038 50.5481 8.32981 47.3971C7.01923 44.2462 2 41.4856 2 38C2 34.5144 6.96346 31.8654 8.32981 28.6029C9.69615 25.3404 7.93942 20.0702 10.5048 17.5048C13.0702 14.9394 18.4519 16.6404 21.6029 15.3298C24.7538 14.0192 27.5144 9 31 9C34.4856 9 37.1346 13.9635 40.3971 15.3298C43.6596 16.6962 48.9298 14.9394 51.4952 17.5048C54.0606 20.0702 52.3596 25.4519 53.6702 28.6029C54.9808 31.7538 60 34.5144 60 38C60 41.4856 55.0365 44.1346 53.6702 47.3971C52.3038 50.6596 54.0606 55.9298 51.4952 58.4952C48.9298 61.0606 43.5481 59.3596 40.3971 60.6702C37.2462 61.9808 34.4856 67 31 67C27.5144 67 24.8654 62.0365 21.6029 60.6702C18.3404 59.3038 13.0702 61.0606 10.5048 58.4952Z'
				stroke='#252B42'
				strokeWidth='3'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M43 31L26.9909 47L19 39'
				stroke='#252B42'
				strokeWidth='3'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default Checked;
