import React from 'react';

const BorderTraingle = () => {
	return (
		<svg
			className='border-triangle'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 40 41'
			fill='none'>
			<path
				d='M21.957 4.92327C26.0875 1.62403 32.2444 3.91854 33.2083 9.11637L37.1173 30.1956C38.1092 35.5444 32.8877 39.931 27.7901 38.0312L7.13009 30.3317C2.03254 28.432 0.955358 21.6981 5.20593 18.303L21.957 4.92327Z'
				stroke='#DFDAFF'
				strokeWidth='5'
			/>
		</svg>
	);
};

export default BorderTraingle;
