import React from 'react';

const Speed = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='53'
			height='51'
			viewBox='0 0 53 51'
			fill='none'>
			<circle cx='14.5889' cy='36.6335' r='14.0687' fill='#E0D9FF' />
			<path
				d='M48.0511 2H10.5364C8.908 2 7.58789 3.28941 7.58789 4.87998V35.12C7.58789 36.7106 8.908 38 10.5364 38H48.0511C49.6796 38 50.9997 36.7106 50.9997 35.12V4.87998C50.9997 3.28941 49.6796 2 48.0511 2Z'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M10.7644 9.41174H47.8232'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinejoin='round'
			/>
			<path
				d='M12.9995 7C13.5518 7 13.9995 6.55228 13.9995 6C13.9995 5.44772 13.5518 5 12.9995 5C12.4472 5 11.9995 5.44772 11.9995 6C11.9995 6.55228 12.4472 7 12.9995 7Z'
				fill='#252B42'
			/>
			<path
				d='M16.9995 7C17.5518 7 17.9995 6.55228 17.9995 6C17.9995 5.44772 17.5518 5 16.9995 5C16.4472 5 15.9995 5.44772 15.9995 6C15.9995 6.55228 16.4472 7 16.9995 7Z'
				fill='#252B42'
			/>
			<path
				d='M14.9996 30.5882C14.9996 22.4014 21.3993 15.7647 29.2938 15.7647C37.1882 15.7647 43.5879 22.4014 43.5879 30.5882'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M43.7054 15L30.9995 27.7059'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M30.9995 31.2678C30.0845 32.2441 28.6009 32.2441 27.6858 31.2678C26.7708 30.2915 26.7707 28.7085 27.6858 27.7322C28.6008 26.7559 30.0845 26.756 30.9995 27.7323'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default Speed;
