import React from 'react';

const Success = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='73'
			height='73'
			viewBox='0 0 73 73'
			fill='none'>
			<g clipPath='url(#clip0_417_8695)'>
				<path
					d='M36.5 73C16.3738 73 0 56.6263 0 36.5C0 16.3737 16.3738 0 36.5 0C42.9037 0 49.2036 1.68224 54.7183 4.86518C55.4655 5.29665 55.7227 6.25555 55.2912 7.00275C55.012 7.48387 54.4895 7.78358 53.9289 7.78358C53.6572 7.78358 53.3891 7.71154 53.1537 7.57567C48.1153 4.66703 42.3566 3.12924 36.5 3.12924C18.0992 3.12924 3.12928 18.0993 3.12928 36.5C3.12928 54.9007 18.0992 69.8708 36.5 69.8708C54.9008 69.8708 69.8708 54.9008 69.8708 36.5C69.8708 35.0074 69.7711 33.507 69.5752 32.0405C69.2299 29.4529 68.5823 26.9163 67.6496 24.5021C67.027 22.8917 66.2783 21.3255 65.4239 19.8463C65.215 19.4843 65.1594 19.0625 65.2676 18.6591C65.3758 18.2556 65.6349 17.9178 65.9973 17.7084C66.235 17.5711 66.5037 17.4986 66.7759 17.4986C67.3354 17.4986 67.856 17.7988 68.1348 18.2818C69.072 19.905 69.8906 21.6185 70.5676 23.3742C71.5918 26.0269 72.3013 28.8034 72.6768 31.6266C72.8914 33.2289 73 34.8682 73 36.5C73 56.6263 56.6263 73 36.5 73Z'
					fill='#1DD000'
				/>
				<path
					d='M31.8424 49.7096C31.4248 49.7096 31.0317 49.5469 30.7361 49.2513L21.42 39.9352C20.8105 39.3254 20.8105 38.3325 21.42 37.7227C21.7157 37.4271 22.1086 37.2643 22.5263 37.2643C22.944 37.2643 23.337 37.4271 23.6326 37.7227L31.302 45.392C31.4513 45.5413 31.6468 45.6159 31.8424 45.6159C32.038 45.6159 32.2336 45.5413 32.3829 45.392L49.3683 28.4066C49.6639 28.111 50.057 27.9482 50.4746 27.9482C50.8923 27.9482 51.2853 28.111 51.5809 28.4066C52.1904 29.0164 52.1904 30.0093 51.5809 30.6191L32.9487 49.2513C32.6531 49.5469 32.2601 49.7096 31.8424 49.7096Z'
					fill='#1DD000'
				/>
			</g>
			<defs>
				<clipPath id='clip0_417_8695'>
					<rect width='73' height='73' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Success;
