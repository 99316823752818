export enum Tag {
	Section = 'section',
	Main = 'main',
	Div = 'div',
	Aside = 'aside',
	Header = 'header',
	Nav = 'nav',
	Footer = 'footer',
	Article = 'article',
}
