exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-pages-uslugi-aplikacje-internetowe-index-tsx": () => import("./../../../src/pages/uslugi/aplikacje-internetowe/index.tsx" /* webpackChunkName: "component---src-pages-uslugi-aplikacje-internetowe-index-tsx" */),
  "component---src-pages-uslugi-strony-internetowe-creative-tsx": () => import("./../../../src/pages/uslugi/strony-internetowe/creative.tsx" /* webpackChunkName: "component---src-pages-uslugi-strony-internetowe-creative-tsx" */),
  "component---src-pages-uslugi-strony-internetowe-headless-cms-tsx": () => import("./../../../src/pages/uslugi/strony-internetowe/headless-cms.tsx" /* webpackChunkName: "component---src-pages-uslugi-strony-internetowe-headless-cms-tsx" */),
  "component---src-pages-uslugi-strony-internetowe-index-tsx": () => import("./../../../src/pages/uslugi/strony-internetowe/index.tsx" /* webpackChunkName: "component---src-pages-uslugi-strony-internetowe-index-tsx" */),
  "component---src-pages-uslugi-strony-internetowe-landing-page-tsx": () => import("./../../../src/pages/uslugi/strony-internetowe/landing-page.tsx" /* webpackChunkName: "component---src-pages-uslugi-strony-internetowe-landing-page-tsx" */),
  "component---src-pages-uslugi-strony-internetowe-multi-page-tsx": () => import("./../../../src/pages/uslugi/strony-internetowe/multi-page.tsx" /* webpackChunkName: "component---src-pages-uslugi-strony-internetowe-multi-page-tsx" */),
  "component---src-pages-uslugi-strony-internetowe-one-page-tsx": () => import("./../../../src/pages/uslugi/strony-internetowe/one-page.tsx" /* webpackChunkName: "component---src-pages-uslugi-strony-internetowe-one-page-tsx" */),
  "component---src-pages-uslugi-strony-internetowe-seo-tsx": () => import("./../../../src/pages/uslugi/strony-internetowe/seo.tsx" /* webpackChunkName: "component---src-pages-uslugi-strony-internetowe-seo-tsx" */)
}

