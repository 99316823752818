import React from 'react';

const LaptopChecked = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='58'
			height='54'
			viewBox='0 0 58 54'
			fill='none'>
			<circle cx='14.6004' cy='14.6264' r='14.0687' fill='#E0D9FF' />
			<path
				d='M12.6875 37.0002V15.125C12.6875 13.3992 14.0867 12 15.8125 12H47.0628C48.7886 12 50.1879 13.3992 50.1879 15.125V29.1877'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinejoin='round'
			/>
			<path
				d='M29.875 16.687H33'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinejoin='round'
			/>
			<path
				d='M33.7815 41.6877H8C8 45.1393 10.7985 47.9378 14.2501 47.9378H33.7815'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinejoin='round'
			/>
			<path
				d='M42.375 43.2502L45.5 46.3752L51.7501 39.0837'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='square'
				strokeLinejoin='round'
			/>
			<circle
				cx='47.0626'
				cy='42.469'
				r='9.68761'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default LaptopChecked;
