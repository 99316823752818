import React from 'react';

const Cooperation = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='47'
			height='57'
			viewBox='0 0 47 57'
			fill='none'>
			<circle cx='32.4944' cy='14.7928' r='14.0687' fill='#F9DCEF' />
			<path
				d='M16.0636 51.4213H7.85815'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M14.9053 43.7335H3.99715C2.6984 43.7335 1.63574 42.6729 1.63574 41.3765V17.3569C1.63574 16.0607 2.6984 15 3.99715 15H35.3218C36.6207 15 37.6832 16.0607 37.6832 17.3569V25.234'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M32.6784 36.6745C35.4457 36.6745 37.6974 34.4268 37.6974 31.6645C37.6974 28.9019 35.4457 26.6544 32.6784 26.6544C29.9106 26.6544 27.6592 28.9019 27.6592 31.6645C27.6592 34.4268 29.9106 36.6745 32.6784 36.6745Z'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M43.7041 55V43.8597C43.7041 41.6256 41.8895 39.8145 39.6512 39.8145H25.7057C23.4674 39.8145 21.6528 41.6256 21.6528 43.8597V55'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='square'
				strokeLinejoin='round'
			/>
			<path
				d='M16.0632 46.2869V51.4215'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinejoin='round'
			/>
			<path
				d='M14.9055 43.7336H18.7844'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinejoin='round'
			/>
			<path
				d='M17.8632 23.922C17.8632 25.6636 16.4486 27.0755 14.7038 27.0755C12.9588 27.0755 11.5442 25.6636 11.5442 23.922C11.5442 22.1804 12.9588 20.7686 14.7038 20.7686C16.4486 20.7686 17.8632 22.1804 17.8632 23.922Z'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M21.6526 36.6743V32.7469C21.6526 31.4052 20.5628 30.3174 19.2183 30.3174H10.1887C8.84429 30.3174 7.75439 31.4052 7.75439 32.7469V36.6743'
				stroke='#252B42'
				strokeWidth='2.5'
				strokeLinecap='square'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default Cooperation;
