import React from 'react';

const Screen = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='76'
			height='76'
			viewBox='0 0 76 76'
			fill='none'>
			<circle cx='44.5' cy='49.5' r='21.5' fill='#F5C9E9' />
			<path
				d='M52.323 53.1942H8.17245C5.21676 53.1942 3 50.7927 3 48.0218V18.4649C3 15.5092 5.40149 13.2925 8.17245 13.2925H52.323C55.2787 13.2925 57.4954 15.694 57.4954 18.4649V48.0218C57.6801 50.7927 55.2787 53.1942 52.323 53.1942Z'
				stroke='#252B42'
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M10.9436 36.0142H22.7663'
				stroke='#252B42'
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M10.9436 44.5117H31.4487'
				stroke='#252B42'
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M42.5322 18.6499C47.3352 18.6499 51.2145 22.5292 51.2145 27.3322C51.2145 32.1352 47.3352 36.1993 42.5322 36.1993C37.7292 36.1993 33.8499 32.3199 33.8499 27.517'
				stroke='#252B42'
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M18.3328 62.8003H42.3477'
				stroke='#252B42'
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M34.9585 53.9331V62.0612'
				stroke='#252B42'
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M25.7219 53.9331V62.0612'
				stroke='#252B42'
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default Screen;
